:where(.css-dev-only-do-not-override-1qazear).ant-btn.ant-btn-block {
  width: 100% !important;
  margin-bottom: 20px !important;
  margin-left: 10px !important;
}

@media only screen and (max-width: 576px) {
  .buttonn {
    margin-bottom: 32px;
  }
}

.view {
  background: orange !important;
}

.addCategory {
  background: darkgray;
}

.delete {
  background: #fc000d !important;
  color: aliceblue;
}

.edit {
  background: green !important;
}


.text-left {
  display: flex !important;
}
/* 
:where(.css-dev-only-do-not-override-1qazear).ant-btn-primary {
  background-color: #000000;
} */

.modalStyle2 .ant-modal-header {
  border-radius: 20px 20px 0 0;
  background-color: skyblue !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.Info {
  font-size: medium;
  color: #000000;
  font-weight: 400
}

.highlight-row {
  /* background-color: rgb(251 233 188); */
  background-color:rgb(226, 241, 255);
  
  /* pointer-events: none;*/
}
.no-hover-effect .ant-table-tbody > tr:hover > td {
  background-color: inherit !important;
}